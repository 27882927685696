import { initPerfume } from 'perfume.js';
import {
  EffectiveConnectionType,
  INavigatorInfo,
  IPerfumeDataConsumption,
  IPerfumeNetworkInformation,
} from 'perfume.js/dist/types/types';

import {
  Metric,
  Payload,
  Timing,
  Vital,
  QueueValue,
  vitalsTypes,
} from './types';
import { vitalNames } from './constants';

async function init() {
  const queue = new Set<QueueValue>();
  let information: INavigatorInfo;

  try {
    const perfume = new initPerfume({
      resourceTiming: true,
      analyticsTracker: options => {
        const { metricName, data, navigatorInformation } = options;
        information = navigatorInformation;

        if (vitalNames.includes(metricName.toLowerCase())) {
          addToQueue({
            name: metricName.toLowerCase() as vitalsTypes,
            value: data as number,
          });
        }

        if (metricName === 'networkInformation') {
          const { effectiveType } = data as IPerfumeNetworkInformation;
          addToQueue({
            name: 'connectionType',
            value: effectiveType,
          });
        }

        if (metricName === 'dataConsumption') {
          const { total } = data as IPerfumeDataConsumption;

          addToQueue({
            name: 'dataConsumption',
            value: total,
          });
        }
      },
    });

    addEventListener('visibilitychange', visibilityChangeHandler);
    addEventListener('pagehide', hidePageHandler);

    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.__LOADO__ = perfume;
    }
  } catch (e) {
    console.info('[Loado]: Web Vitals are not supported in this environment.');
  }

  function visibilityChangeHandler() {
    if (typeof document === 'object' && document.visibilityState === 'hidden') {
      flushQueue();
      removeEventListener('visibilitychange', visibilityChangeHandler);
    }
  }

  function hidePageHandler() {
    flushQueue();
    removeEventListener('pagehide', visibilityChangeHandler);
  }

  function addToQueue(metric: QueueValue) {
    queue.add(metric);
  }

  function flushQueue() {
    if (queue.size > 0) {
      const vitals = getUserVitals();
      const timings = getUserTimings();
      const connectionType = getConnectionType();

      const payload: Payload = {
        isLowEndExperience: !!information.isLowEndExperience,
        isLowEndDevice: !!information.isLowEndDevice,
        timings,
        vitals,
        origin: window.location.origin,
        connectionType,
        pagePathname: window.location.pathname,
      };

      sendToAnalytics(payload);
      queue.clear();
    }
  }

  function getUserVitals(): Vital[] {
    const vitals = Array.from(queue).filter(metric => {
      return vitalNames.includes(metric.name);
    }) as Metric[];

    return vitals.map(metric => {
      return {
        name: metric.name,
        value: metric.value,
      };
    });
  }

  function getConnectionType(): EffectiveConnectionType | undefined {
    const connectionType = Array.from(queue).find(
      metric => metric.name === 'connectionType'
    );

    return connectionType?.value as EffectiveConnectionType | undefined;
  }

  function getUserTimings(): Timing[] {
    return performance.getEntriesByType('measure').map(timing => {
      return {
        name: timing.name,
        duration: timing.duration,
        startTime: timing.startTime,
      };
    });
  }
}

function sendToAnalytics(metric: Payload) {
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://loado.dev';

  const url = `${baseUrl}/api/sdk/loado`;
  const body = JSON.stringify(metric);

  if (process.env.NODE_ENV === 'development') {
    console.info('[Loado]: Sending payload to', url, body);
  }

  if (process.env.NODE_ENV === 'production') {
    (navigator.sendBeacon && navigator.sendBeacon(url, body)) ||
      fetch(url, {
        body: body,
        method: 'POST',
        keepalive: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
  }
}

init();
